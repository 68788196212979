import { useQuery } from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react/solid';
import type { InferGetStaticPropsType } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ClimbingBoxLoader } from 'react-spinners';
import Header from '../components/Header';
import { MenuCardSquare } from '../components/MenuCard';
import NavBar from '../components/NavBar';
import { GetStoresQuery } from '../generated';
import { GET_STORES } from '../graphql/queries';
import { initializeApollo } from '../utils/apollo';

interface HomeProps extends InferGetStaticPropsType<typeof getStaticProps> {}

const Home: React.FC<HomeProps> = () => {
  const router = useRouter();
  const { data } = useQuery<GetStoresQuery>(GET_STORES);

  const onViewMoreButtonClick = (store: string) => {
    router.push(`/${store}`);
  };

  if (data) {
    const { getStores } = data;

    return (
      <div className="overflow-hidden">
        <Header />
        <NavBar />

        <main className="py-8 space-y-12">
          {getStores.map((store) => {
            const visibleMenus = store?.menus?.slice(0, 10);
            return (
              <section key={`store-${store?.storeEngName}`} className="space-y-4">
                <div className="pl-6 flex items-center justify-between pr-6">
                  <div
                    className={`relative w-32 h-10 flex items-center ${
                      !store?.logoImageOriginalLocation && 'opacity-30'
                    }`}
                  >
                    <Image
                      src={store?.logoImageOriginalLocation || '/assets/images/image-not-found.png'}
                      alt={`${store?.storeName} 로고`}
                      layout="fill"
                      objectFit="contain"
                      priority={true}
                    />
                  </div>

                  <button
                    onClick={() => onViewMoreButtonClick(store?.storeEngName as string)}
                    className="flex items-center space-x-2 pl-4 pr-3 py-1 border border-cmGreen-400 text-cmGreen-400 hover:opacity-60 active:opacity-100"
                  >
                    <span>더 보기</span>
                    <ArrowRightIcon width={16} height={16} />
                  </button>
                </div>
                <div className="inline-flex overflow-hidden">
                  {visibleMenus && visibleMenus.length !== 0 ? (
                    visibleMenus
                      .sort((a, b) => b?.eatenCount! - a?.eatenCount!)
                      .map((menu, id) => (
                        <MenuCardSquare
                          key={`store-${store?.storeEngName}-${id}`}
                          menuId={menu?.id as number}
                          storeEngName={store?.storeEngName as string}
                          menuName={menu?.menuName as string}
                          price={menu?.price.toString() as string}
                          eatenCount={menu?.eatenCount.toString() as string}
                          viewCount={menu?.viewCount.toString() as string}
                          imageUrl={menu?.imageLocation as string}
                        />
                      ))
                  ) : (
                    <div className="flex items-center pl-6 h-24 text-center text-xl font-bold text-gray-300">
                      아직 메뉴가 없습니다.
                    </div>
                  )}
                </div>
              </section>
            );
          })}

          <section className="pt-10">
            <div className="text-center text-2xl text-gray-200 font-bold">추가 상점 준비 중</div>
          </section>
        </main>
        <footer></footer>
      </div>
    );
  } else {
    return (
      <div>
        <Header
          title={`커스텀 메뉴`}
          description={`커스텀 메뉴를 확인해보세요.`}
          keywords={`커스텀메뉴,커스텀,메뉴,만들기,조합,레시피,음료,꿀조합`}
        />
        <NavBar />
        <div className="text-center pt-20 pb-14 text-2xl font-bold text-gray-400">
          <ClimbingBoxLoader loading={true} color="rgba(16, 108, 41, 1)" />
        </div>
        <div className="flex w-full justify-center mt-10">
          <button
            disabled={true}
            className="bg-cmGreen-400 text-white px-3 py-2 hover:opacity-80 active:opacity-100 disabled:pointer-events-none"
          >
            로딩 중...
          </button>
        </div>
      </div>
    );
  }
};

export const getStaticProps = async () => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<GetStoresQuery>({
    query: GET_STORES,
  });

  return {
    props: {
      stores: data,
    },
    revalidate: 1,
  };
};

export default Home;
